import React from "react"
import __c from "../utilities/combineClasses"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PriceComparison from "../components/price-comparison"

import featureMap from "../../content/yaml/tiers.yaml"

import style from "./pricing.module.scss"

export default ({ data }) => {
  return (
    <Layout className={style.gridLayout}>
      <SEO title="Pricing" />
      <article className={__c(style.article)}>
        <header className={__c(style.header)}>
          <h1 className={__c(style.pageTitle)}>Pricing</h1>
        </header>
        <main className={__c(style.main)}>
          <section className={__c(style.introduction)}>
            <p>
              We&apos;ll make it easy to get started with collections of the
              most common resources and features for your organisation.
            </p>
          </section>
          <PriceComparison data={featureMap} className={style.pricingGrid} />
        </main>
        <footer></footer>
      </article>
    </Layout>
  )
}
