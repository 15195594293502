import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import ls from "local-storage"
import __c from "../utilities/combineClasses"

import style from "./price-comparison.module.scss"

const numberFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

export default class PriceComparison extends React.Component {
  static propTypes = {
    data: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      priceMode: `annually`,
      priceModeSwitchWidth: `50px`,
      currentSelection: `explore`,
      view: `mobile`,
    }
    this.resizeTimeout = undefined
  }

  componentDidMount = () => {
    window.requestAnimationFrame(() => {
      this.calcViewportSize()
      let widthElement = document.querySelector(
        `button.${style.durationSwitchButton}[data-active="true"]`
      )
      this.setState({
        priceModeSwitchWidth: `${widthElement.clientWidth}px`,
      })
    })
    window.addEventListener("resize", this.changeViewport)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.changeViewport)
  }

  calcViewportSize = () => {
    this.setState({
      view:
        window && window.matchMedia("(min-width: 640px)").matches
          ? `rich`
          : `mobile`,
    })
  }

  changeViewport = e => {
    window.clearTimeout(this.resizeTimeout)
    this.resizeTimeout = window.setTimeout(this.calcViewportSize, 100)
  }

  changeTier = e => {
    if (e.currentTarget.dataset.tier === undefined) {
      return false
    }
    this.setState({
      currentSelection: e.currentTarget.dataset.tier,
    })
    ls.set(`tierSelection`, e.currentTarget.dataset.tier)
  }

  changePriceMode = e => {
    if (e.currentTarget.dataset.pricemode === undefined) {
      return false
    }
    this.setState({
      priceMode: e.currentTarget.dataset.pricemode,
      priceModeSwitchWidth: `${e.currentTarget.clientWidth}px`,
    })
    ls.set(`priceMode`, e.currentTarget.dataset.pricemode)
  }

  renderSignUpLink = ({ key, cost }) => (
    <Link
      to="/sign-up"
      className={__c([`aside-button`, style.freeTrialButton])}
      state={{
        chosenCollection: key,
        chosenRate: this.state.priceMode,
      }}
    >
      {cost.monthly ? `Start your 14 day trial` : `Start now`}
    </Link>
  )

  renderMobile = (data, priceMode) => {
    return data.tiers.map((tier, index) => (
      <div className={__c(style.tier)} key={tier.idKey}>
        <TierHeader
          key={tier.idKey}
          tierName={tier.tierName}
          price={tier.price}
          priceMode={priceMode}
        >
          <p>{tier.description}</p>
        </TierHeader>
        <div className={__c(style.features)}>
          {index > 0 ? (
            <p className={__c(style.featuresLeadIn)}>
              &hellip;everything offered in{" "}
              <span>{data.tiers[index - 1].tierName}</span> plus:
            </p>
          ) : (
            false
          )}
          <ul className={__c(style.featuresList)}>
            {data.features.map(feature =>
              tier.features.new.includes(feature.idKey) ? (
                <Feature key={feature.idKey} {...feature} />
              ) : (
                false
              )
            )}
          </ul>
          {this.renderSignUpLink({ key: tier.idKey, cost: tier.price })}
        </div>
      </div>
    ))
  }

  renderRich = (data, priceMode, currentSelection) => {
    const tierKeys = data.tiers.reduce((map, obj) => {
      map[obj.idKey] = obj.features
      return map
    }, {})
    return (
      <>
        <nav className={__c(style.tiers)}>
          {data.tiers.map(tier => (
            <button
              key={tier.idKey}
              className={__c(style.tier)}
              role="tab"
              onClick={this.changeTier}
              data-tier={tier.idKey}
              data-active={tier.idKey === currentSelection}
            >
              <TierHeader
                tierName={tier.tierName}
                price={tier.price}
                priceMode={priceMode}
              />
            </button>
          ))}
        </nav>
        <div className={__c(style.tierDescription)}>
          {data.tiers.map(tier => (
            <div
              key={tier.idKey}
              className={__c([
                style.tierDescriptionParagraph,
                tier.idKey === currentSelection ? style.isActive : false,
              ])}
            >
              <p>{tier.description}</p>
            </div>
          ))}
        </div>
        <ul className={__c(style.features)}>
          {data.features.map(feature => (
            <Feature
              key={feature.idKey}
              {...feature}
              cu={tierKeys[currentSelection].all.includes(feature.idKey)}
              nu={tierKeys[currentSelection].new.includes(feature.idKey)}
            />
          ))}
        </ul>
      </>
    )
  }

  render = () => {
    const { data, className } = this.props
    return (
      <section className={__c([style.component, className])}>
        <header className={__c(style.header)}>
          <p className={__c(style.headerIntro)}>
            Enjoy a discount of up to 20% for buying for the year.
          </p>
          <div className={__c(style.durationSwitch)}>
            <div
              className={__c(style.durationSwitchInner)}
              data-pricemode={this.state.priceMode}
              style={{ "--switch-width": this.state.priceModeSwitchWidth }}
            >
              <button
                className={__c([
                  style.durationSwitchButton,
                  style.durationSwitchMonthly,
                ])}
                onClick={this.changePriceMode}
                data-pricemode="monthly"
                data-active={this.state.priceMode === `monthly`}
              >
                Monthly
              </button>
              <button
                className={__c([
                  style.durationSwitchButton,
                  style.durationSwitchAnnually,
                ])}
                onClick={this.changePriceMode}
                data-pricemode="annually"
                data-active={this.state.priceMode === `annually`}
              >
                Annually
              </button>
            </div>
          </div>
        </header>
        <main className={__c(style.main)}>
          {this.state.view === `rich`
            ? this.renderRich(
                data,
                this.state.priceMode,
                this.state.currentSelection
              )
            : this.renderMobile(data, this.state.priceMode)}
        </main>
        <footer className={__c(style.footer)}>
          <p>
            Need more? We can help. Contact us at{" "}
            <a href="mailto:enterprise@variametrix.com">
              enterprise@variametrix.com
            </a>{" "}
            to provision customization for your company&apos;s unique needs.
          </p>
        </footer>
      </section>
    )
  }
}

class TierHeader extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    tierName: PropTypes.string.isRequired,
    price: PropTypes.object.isRequired,
    priceMode: PropTypes.string.isRequired,
  }

  setPriceModeString = (price, priceMode) => {
    var moneyString = ``
    var durationString = ``
    if (price[priceMode] === 0.0) {
      moneyString = `Free`
      durationString = `forever`
    } else {
      moneyString = numberFormat.format(price[priceMode])
      if (priceMode === `monthly`) {
        durationString = `per month`
      } else {
        durationString = `per year`
      }
    }
    return (
      <p className={__c(style.tierPrice)}>
        <span className={__c(style.tierPriceAmount)}>{moneyString}</span>
        <span className={__c(style.tierPriceDuration)}>{durationString}</span>
        {priceMode === `annually` && price.monthly !== 0.0 ? (
          <span className={__c(style.tierPriceSavings)}>
            (save{" "}
            {Math.floor(
              ((price.monthly * 12 - price.annually) / (price.monthly * 12)) *
                100
            )}{" "}
            %)
          </span>
        ) : (
          false
        )}
      </p>
    )
  }

  render = () => {
    const { children, tierName, price, priceMode } = this.props
    return (
      <>
        <h2 className={__c(style.tierName)}>{tierName}</h2>
        {this.setPriceModeString(price, priceMode)}
        {children ? (
          <div className={__c(style.tierDescription)}>{children}</div>
        ) : (
          false
        )}
      </>
    )
  }
}

class Feature extends React.Component {
  static propTypes = {
    idKey: PropTypes.string.isRequired,
    featureName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    cu: PropTypes.bool,
    nu: PropTypes.bool,
    icon: PropTypes.number.isRequired,
  }

  render = () => {
    const { featureName, description, cu, nu, icon } = this.props
    return (
      <li
        className={__c(style.feature)}
        style={{ "--item-icon-offset": icon }}
        title={description}
        data-current={cu}
        data-new={nu}
      >
        <span className={__c(style.featureName)}>{featureName}</span>
      </li>
    )
  }
}
